import falafel from "falafel"

export default (code) => {
    let __idCounter__ = 0;
    let __events__ = [];

    function createEvent(type, payload) {
        return {
            type: type,
            payload: payload
        }
    }

    const InstrumentFunctions = {
        enterFunction: (id, name, params, locals) => __events__.push(createEvent('EnterFunction', { id, name, params, locals })),
        exitFunction: (id, name) => __events__.push(createEvent('ExitFunction', { id, name, })),
        consoleLog: (log) => __events__.push(createEvent('ConsoleLog', log))
    }

    // Benötigt, damit Vue.js sich nicht beschwert, die Variablen würden nicht verwendet
    console.log(__idCounter__);
    console.log(InstrumentFunctions);

    function instrumentBlock(code, fnName, params, locals) {
        return `{
            const __id__ = __idCounter__++;
            let __params__ = [${params.map(p => `"${p}"`)}];
            __params__ = __params__.map(p => ({name: p , value: arguments[__params__.indexOf(p)]}));
            let __locals__ = [${locals.join(",")}];
            InstrumentFunctions.enterFunction(__id__, "${fnName}", __params__, __locals__);
            try {
              ${code}
            } finally {
                InstrumentFunctions.exitFunction(__id__, "${fnName}");
            }
        }`
    }
    
    function instrumentCall(code, fnName, params, objectName) {
        return `(()=>{
            const __id__ = __idCounter__++
            const __evaluatedParams__ = [${params}];
            InstrumentFunctions.enterFunction(__id__, "${fnName}(" + __evaluatedParams__.join(", ") + ")");
            if("${objectName}" === "console"){
                if("${fnName}" === "log")
                InstrumentFunctions.consoleLog({ type: "log", content: __evaluatedParams__.join(" ")});
                else if("${fnName}" === "warn")
                InstrumentFunctions.consoleLog({type: "warn", content: __evaluatedParams__.join(" ")});
                else if("${fnName}" === "error")
                InstrumentFunctions.consoleLog({type: "err", content: __evaluatedParams__.join(" ")});
            }
            InstrumentFunctions.exitFunction(__id__, "${fnName}");
            return ${code.replace(new RegExp("(?<=" + fnName + ").*", "s"), "(...__evaluatedParams__)")};
        })()`
    }

    const output = falafel(code, (node) => {
        const parentType = node.parent && node.parent.type;
        const isFunctionBody = parentType === "FunctionDeclaration" || parentType === "FunctionExpression" || parentType === "ArrowFunctionExpression"

        // Knoten ist ein Funktionskörper
        if (node.type === "BlockStatement" && isFunctionBody) {
            const fnName = node.parent.id && node.parent.id.name ? node.parent.id.name : "anonymous";
            const block = node.source();
            const blockContent = block.substring(1, block.length - 1);
            const variableDeclarations = node.body.filter(n => n.type === "VariableDeclaration").map(n => n.declarations).flat();
            const locals = variableDeclarations.map(n => ({ name: n.id.name, value: n.init ? n.init.value : "" })).map(l => JSON.stringify(l));
            const params = node.parent.params.map(p => p.name);
            node.update(instrumentBlock(blockContent, fnName, params, locals));
        }

        //Knoten ist Aufruf einer Memberfunktion
        if (node.type === "CallExpression" && node.callee.type === "MemberExpression") {
            const fnName = node.callee.property.name;
            let params = node.arguments.map(a => a.source())
            node.update(instrumentCall(node.source(), fnName, params, node.callee.object.name))
        }
    });
    eval(output.toString());
    return __events__;
}