export default {
    helloWorld: `function hello(s) {
    console.log("Hello " + s);
}
hello("World");`,

    faculty: `function facIter(n){
    let result = 1;
    for (let i = 2; i <= n; i++) {
        result *= i;
    }
    return result;
}
console.log("Ergebnis: " + facIter(4));`,

    facultyRecursive: `function facRec(n) {
    if(n <= 1){
        return 1;
    } else {
        return n * facRec(n-1);
    }
}
console.log("Ergebnis: " + facRec(4));`,

    fibonacci: `function fibonacci(n){
    let a = 1, b = 0, temp;
    
    while (n >= 0){
        temp = a;
        a = a + b;
        b = temp;
        n--;
    }
      return b;
}
// n+1te Stelle der Fibonacci-Folge (1, 1, 2, 3, 5, 8, 13, 21, ...)
console.log("Ergebnis: " + fibonacci(5));`,

    fibonacciRecursive: `function fibonacciRec(n) {
    if (n <= 1) return 1;
      
    return fibonacciRec(n - 1) + fibonacciRec(n - 2);
}
// n+1te Stelle der Fibonacci-Folge (1, 1, 2, 3, 5, 8, 13, 21, ...)
console.log("Ergebnis: " + fibonacciRec(5));`
}