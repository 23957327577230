<template>
  <v-card color="cardbackground" class="mt-2">
    <v-card-title class="pt-2" @click="expandCard">
      {{ name }}
    </v-card-title>
    <v-expand-transition>
      <div v-if="expand && (params || locals)">
        <v-card-text class="pa-0" @click="expandCard">
          <div class="stack-frame-section">
            <div class="overline font-weight-bold">Parameter</div>
            <div
              class="keyvalue text-subtitle-1"
              v-for="param in params"
              :key="param.name"
            >
              {{ param.name }}: {{ param.value }}
            </div>
            <div v-if="params.length === 0">—</div>
          </div>
          <div class="stack-frame-section">
            <div class="overline font-weight-bold">Lokale Variablen</div>
            <div
              class="keyvalue text-subtitle-1"
              v-for="local in locals"
              :key="local.name"
            >
              {{ local.name }} = {{ local.value }}
            </div>
            <div v-if="locals.length === 0">—</div>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: "CallStackFrame",
  props: ["name", "params", "locals", "expand"],
  data: () => ({}),
  methods: {
    expandCard() {
      this.expand = !this.expand;
    },
  },
};
</script>

<style>
.stack-frame-section {
  padding: 4px 16px;
}
.keyvalue {
  word-spacing: 2pt;
}
</style>