import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.deepPurple.lighten1,
                secondary: colors.grey.lighten2,
                accent: colors.shades.black,
                error: colors.red.accent3,
                shade: colors.deepPurple.lighten2,
                consolebackground: '#282a36',
                cardbackground: colors.deepPurple.lighten3,
                moon: colors.deepPurple.darken4
            },
            dark: {
                primary: colors.deepPurple.darken2,
                secondary: '#22242e',
                accent: colors.shades.black,
                error: colors.red.accent3,
                shade: colors.deepPurple.darken3,
                consolebackground: '#22242e',
                cardbackground: colors.deepPurple.darken2,
                moon: colors.yellow.darken1
            },
        },
    },
});
