<template>
  <v-card color="secondary" class="d-flex flex-column flex-grow-1">
    <v-card-title
      >Call Stack <v-spacer></v-spacer>
      <v-switch v-model="autoExpand"></v-switch
    ></v-card-title>
    <v-card-text class="d-flex flex-column-reverse flex-grow-1" id="callstack">
      <v-slide-y-transition group>
        <CallStackFrame
          v-for="s in $store.state.stackFrames"
          :key="s.id"
          :name="s.name"
          :params="s.params"
          :locals="s.locals"
          :expand="autoExpand"
        />
      </v-slide-y-transition>
    </v-card-text>
  </v-card>
</template>

<script>
import CallStackFrame from "./CallStackFrame";

export default {
  name: "CallStack",
  components: {
    CallStackFrame,
  },
  data: () => ({
    autoExpand: false,
  }),
  computed: {},
  methods: {},
};
</script>

<style>
#callstack {
  overflow-y: auto;
  height: 0;
  min-height: 50vh;
}
</style>