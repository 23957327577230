import Vue from "vue"
import App from "./App.vue"
import vuetify from "./plugins/vuetify"
import store from "./store"

import VueCodemirror from "vue-codemirror"
import "codemirror/lib/codemirror.css"
import "codemirror/theme/dracula.css"
import "codemirror/theme/ayu-mirage.css"
import "codemirror/mode/javascript/javascript"

Vue.use(VueCodemirror);

Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  render: h => h(App)
}).$mount("#app")
