<template>
  <v-app>
    <v-app-bar app color="primary" dark flat hide-on-scroll>
      <v-slide-y-reverse-transition>
        <div class="d-flex align-space-between" v-if="showTitle">
          <v-icon class="mr-2" color="deep-purple lighten-4">mdi-buffer</v-icon>
          <v-app-bar-title class="no-wrap">Call Stack Visualizer</v-app-bar-title>
        </div>
      </v-slide-y-reverse-transition>

      <v-spacer></v-spacer>

      <v-btn
        text
        rounded
        color="moon"
        @click="toggleDarkTheme"
        @mouseenter="toggleDarkThemeHoverState"
        @mouseleave="toggleDarkThemeHoverState"
      >
        <v-expand-x-transition>
          <div v-if="darkThemeHover && !mobileDevice">
            <span>{{
              darkThemeActive ? "Nachtmodus: An" : "Nachtmodus: Aus"
            }}</span>
          </div>
        </v-expand-x-transition>
        <v-icon>mdi-moon-waxing-crescent</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid fill-height align-content-space-between>
        <v-row dense align-content="stretch">
          <v-col lg="6" sm="6" cols="12" class="d-flex flex-column">
            <CodeEditor class="pa-0 maincard flex-grow-1" />
          </v-col>

          <v-col lg="3" sm="6" cols="12" class="d-flex flex-column">
            <CallStack class="pa-0 maincard flex-grow-1" />
          </v-col>

          <v-col lg="3" sm="12" cols="12" class="d-flex flex-column">
            <Console class="pa-0 maincard flex-grow-1" />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import CallStack from "./components/CallStack";
import CodeEditor from "./components/CodeEditor";
import Console from "./components/Console";

export default {
  name: "App",

  components: {
    CallStack,
    CodeEditor,
    Console,
  },

  data: () => ({
    darkThemeHover: false,
    showTitle: false,
  }),
  computed: {
    darkThemeActive() {
      return this.$vuetify.theme.dark ? true : false;
    },
    mobileDevice() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        default:
          return false;
      }
    },
  },
  methods: {
    toggleDarkThemeHoverState() {
      this.darkThemeHover = !this.darkThemeHover;
    },
    toggleDarkTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
  mounted() {
    this.showTitle = true;
  },
};
</script>
<style>
.row {
  height: 100%;
}
</style>
