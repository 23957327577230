<template>
  <v-card color="secondary" class="d-flex flex-column pb-4">
    <v-card-title class="pb-0">
      <v-select
        :items="items"
        v-model="selected"
        @change="loadExampleCode"
        label="Beispielprogramm"
        filled
        dense
      ></v-select>
    </v-card-title>
    <v-card-text class="d-flex flex-column flex-grow-1 pb-0">
      <codemirror
        v-model="code"
        :options="codemirrorOptions"
        class="d-flex flex-column flex-grow-1"
      />
    </v-card-text>
    <v-card-actions class="px-4 pt-0">
      <v-row class="flex-wrap">
        <v-col cols="12" md="4">
          <v-btn
            color="primary"
            @click="runCode"
            :loading="loading"
            :disabled="false"
            block
            >Run <v-icon right>mdi-play</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn
            color="yellow darken-3"
            :disabled="!running"
            block
            @click="autoStep"
          >
            {{ autoStepping ? "Pause" : "Autostep"
            }}<v-icon right>{{
              autoStepping ? "mdi-pause" : "mdi-fast-forward"
            }}</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn
            color="yellow darken-3"
            :disabled="!running"
            block
            class="flex-shrink-1"
            @click="nextStep"
          >
            Next Step
            <v-icon right>mdi-forward</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import exampleCode from "../exampleCode";
import generateEvents from "../generateEvents";

export default {
  name: "CodeEditor",
  components: {},
  data: () => ({
    running: false,
    loading: false,
    selected: "Hello World",
    items: [
      "Hello World",
      "Fakultät iterativ",
      "Fakultät rekursiv",
      "Fibonacci iterativ",
      "Fibonacci rekursiv",
    ],
    code: exampleCode.helloWorld,
    autoStepping: false,
    events: [],
    currentEventIndex: 0,
  }),
  computed: {
    codemirrorOptions() {
      return {
        tabSize: 2,
        mode: "text/javascript",
        lineNumbers: true,
        theme: this.$vuetify.theme.dark ? "dracula" : "default",
      };
    },
  },
  methods: {
    loadExampleCode() {
      switch (this.selected) {
        case "Hello World":
          this.code = exampleCode.helloWorld;
          break;
        case "Fakultät iterativ":
          this.code = exampleCode.faculty;
          break;
        case "Fakultät rekursiv":
          this.code = exampleCode.facultyRecursive;
          break;
        case "Fibonacci iterativ":
          this.code = exampleCode.fibonacci;
          break;
        case "Fibonacci rekursiv":
          this.code = exampleCode.fibonacciRecursive;
          break;
        default:
          this.code = "";
      }
    },
    runCode() {
      this.running = false;
      this.loading = true;
      this.$store.commit("clearStackFrames");
      this.$store.commit("clearConsoleOutput");
      this.currentEventIndex = 0;
      try {
        this.events = generateEvents(this.code);
        // Timeout nur aus kosmetischen Gründen (Ladeindikator)
        setTimeout(() => {
          this.loading = false;
          this.running = true;
        }, 300);
      } catch (error) {
        this.$store.commit("consoleLog", { type: "err", content: error });
        this.loading = false;
      }
    },
    nextStep() {
      // Passende Aktion für konkretes Event ausführen
      if (this.currentEventIndex < this.events.length) {
        const event = this.events[this.currentEventIndex];
        switch (event.type) {
          case "EnterFunction":
            this.$store.commit("pushStackFrame", event.payload);
            break;
          case "ExitFunction":
            this.$store.commit("popStackFrame");
            break;
          case "ConsoleLog":
            this.$store.commit("consoleLog", event.payload);
            break;
        }
        this.currentEventIndex++;
      }
      // Ausführung beenden
      if (this.currentEventIndex === this.events.length) {
        this.running = false;
      }
    },
    autoStep() {
      this.autoStepping = !this.autoStepping;
      let autoStepper = setInterval(() => {
        if (this.running && this.autoStepping) {
          this.nextStep();
        } else {
          this.autoStepping = false;
          clearInterval(autoStepper);
        }
      }, 800);
    },
  },
  watch: {
    code: function () {
      this.running = false;
    },
  },
};
</script>

<style>
.CodeMirror {
  border-radius: 4px;
  flex-grow: 1;
  font-size: 13pt;
}
</style>