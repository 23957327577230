import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        stackFrames: [],
        consoleOutput: [{type: "log", content: "Hello World"}],
    },
    getters: {},
    mutations: {
        pushStackFrame(state, payload) {
            state.stackFrames.unshift(payload);
        },
        popStackFrame(state) {
            state.stackFrames.shift();
        },
        clearStackFrames(state) {
            state.stackFrames = [];
        },
        clearConsoleOutput(state) {
            state.consoleOutput = [];
        },
        consoleLog(state, payload) {
            state.consoleOutput.push(payload);
        },
    },
    actions: {}
});