<template>
  <v-card class="d-flex flex-column" color="consolebackground" dark>
    <v-card-title class="white--text justify-space-between">
      Konsole
      <div id="traffic-lights">
        <v-icon color="red lighten-1" dense>mdi-checkbox-blank-circle</v-icon>
        <v-icon color="yellow darken-2" dense>mdi-checkbox-blank-circle</v-icon>
        <v-icon color="green lighten-1" dense>mdi-checkbox-blank-circle</v-icon>
      </div>
    </v-card-title>
    <v-card-text id="consoleCardText" class="flex-grow-1 text-subtitle-1">
      <v-slide-y-transition group>
        <span v-for="(log, i) in $store.state.consoleOutput" :key="i" :class="log.type">{{ log.content }}<br></span>
      </v-slide-y-transition>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Console",
  data: () => ({
    output: "",
  }),
};
</script>

<style>
#traffic-lights > *:not(:first-child) {
  margin-left: 4px;
}
#consoleCardText {
  min-height: 20vh;
  height: 0;
  overflow-y: auto;
}
.err{
  color: #ff6e63;
}

.warn{
  color: sandybrown;
}
</style>